<template>
  <v-dialog v-model="dialog" width="500">
    <template #activator="{ on, attrs }">
      <v-btn
          color="grey lighten-1"
          class="info-icon"
          fab dark
          v-bind="attrs"
          v-on="on"
          x-small>
        <v-icon color="black">fad fa-info-circle</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>Informatie over uitvoering</v-card-title>
      <v-card-text v-html="value">
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn color="primary" @click="dialog = false">Sluit venster</v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
export default {
  name: 'ImplementationDialog',
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
  }),
};
</script>

<style scoped>
.info-icon {
  position: absolute;
  left:     5px;
  top:      5px;
}
</style>
