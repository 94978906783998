<template>
  <v-col cols="12" md="6">
    <v-row justify="center" class="ma-0" v-if="hasSelected">
      <v-col cols="3">
        <SelectableOption
          :item="selectedBrand"
          select-text="Wijzigen"
          @selected="handleChangeArticle"
        />
      </v-col>
      <v-col cols="3">
        <SelectableOption
          :item="selectedModel"
          select-text="Wijzigen"
          @selected="handleChangeArticle(selectedBrand)"
        />
      </v-col>
      <v-col cols="3">
        <SelectableOption
          :item="selectedImplementation"
          select-text="Wijzigen"
          @selected="handleChangeArticle(selectedBrand, selectedModel)"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import SelectableOption from '../SelectableOption.vue';

export default {
  name: 'HeroSelectedArticles',
  components: { SelectableOption },
  methods: {
    ...mapMutations({
      setBrand: 'filter/setBrand',
      setModel: 'filter/setModel',
      removeFilters: 'filter/removeFilters',
    }),
    handleChangeArticle(brand = null, model = null) {
      this.removeFilters();
      this.setBrand(brand);
      this.setModel(model);

      this.$router.push({ name: 'order' });
    },
  },
  computed: {
    ...mapGetters({
      selectedBrand: 'filter/selectedBrand',
      selectedModel: 'filter/selectedModel',
      selectedImplementation: 'filter/selectedImplementation',
    }),
    hasSelected() {
      return this.selectedBrand && this.selectedModel && this.selectedImplementation;
    },
  },
};
</script>

<style scoped>

</style>
