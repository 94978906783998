<template>
  <hero :large="large">
    <heroBreadcrumbs v-if="breadcrumbs" :breadcrumbs="breadcrumbs" :title="title"/>
    <heroText v-else/>
    <heroSelectedArticles v-if="articles"/>
    <heroArticleSearcher v-else-if="canSearch"/>
  </hero>
</template>

<script>
import Hero from './Hero.vue';
import HeroBreadcrumbs from './HeroBreadcrumbs.vue';
import HeroText from './HeroText.vue';
import HeroArticleSearcher from './HeroArticleSearcher.vue';
import HeroSelectedArticles from './HeroSelectedArticles.vue';

export default {
  name: 'TheHero',
  components: {
    HeroSelectedArticles,
    HeroArticleSearcher,
    HeroText,
    HeroBreadcrumbs,
    Hero,
  },
  data() {
    return {
      large: true,
      breadcrumbs: [],
      articles: null,
      title: '',
      canSearch: true,
    };
  },
  created() {
    this.$hero.$on('setLarge', () => {
      this.large = true;
    });
    this.$hero.$on('setSmall', () => {
      this.large = false;
    });
    this.$hero.$on('setBreadcrumbs', (breadcrumbs) => {
      this.breadcrumbs = breadcrumbs;
    });
    this.$hero.$on('setTitle', (title) => {
      this.title = title;
    });
    this.$hero.$on('setSelectedArticles', (articles) => {
      this.articles = articles;
    });
    this.$hero.$on('setCanSearch', (canSearch) => {
      this.canSearch = canSearch;
    });
  },
};
</script>

<style scoped>

</style>
