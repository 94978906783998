<template>
  <v-list>
    <div v-for="item in menuItems" :key="item.title">
      <v-list-group
        :disabled="item.disabled"
        :prepend-icon="item.icon"
        no-icon
        v-if="typeof item.items !== 'undefined'"
        v-model="item.active"
      >
        <v-list-item slot="activator">
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :key="subItem.title"
          :to="subItem.route"
          v-for="subItem in item.items"
          :value="$route.name === subItem.route"
        >
          <v-list-item-icon class="d-flex justify-center">
            <v-icon>{{ subItem.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ subItem.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-item :to="item.route" v-else :value="$route.name === item.route">
        <v-list-item-icon class="d-flex justify-center">
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>
      <v-divider class="my-2" />
      <v-list-item v-if="isLoggedIn" :to="{name: 'profile'}">
        <v-list-item-icon class="d-flex justify-center">
          <v-icon>fa-user</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Profiel</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-else :to="{name: 'login'}">
        <v-list-item-icon class="d-flex justify-center">
          <v-icon>fa-user</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Inloggen</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
  </v-list>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MainMenu",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "authorisation/isLoggedIn",
    }),
    menuItems() {
      return [
        {
          icon: "fa-home",
          title: "Home",
          route: "/",
        },
        ...this.items,
      ];
    },
  },
};
</script>
