<template>
  <v-col cols="6" class="white--text pa-12" v-if="$vuetify.breakpoint.mdAndUp">
    <h1 class="text-h3">{{ title }}</h1>
    <v-breadcrumbs :items="breadcrumbs" dark class="pa-0 mt-5"/>
  </v-col>
</template>

<script>
export default {
  name: 'HeroBreadcrumbs',
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

