<template>
  <header>
    <!-- TODO: Replace header image of website -->
    <v-img width="100%" :height="height" src="https://picsum.photos/id/11/1800/300">
      <v-row>
        <slot></slot>
      </v-row>
    </v-img>
  </header>
</template>

<script>
export default {
  name: 'Hero',
  props: {
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    height() {
      if (this.large) return '360px';
      return '200px';
    },
  },
};
</script>

<style scoped>
.v-image {
  transition: height 0.3s;
}
</style>
