<template>
  <div id="app">
    <v-app id="inspire">
      <v-navigation-drawer
        app
        fixed
        v-model="mobileMenu"
        v-if="$vuetify.breakpoint.smAndDown"
      >
        <v-img
          :src="require('../assets/logo.png')"
          @click="$router.push({ name: 'home' })"
          alt="FIS Automotive"
          class="ma-4"
        />
        <MainMenu :items="menuItems" />
      </v-navigation-drawer>
      <v-app-bar app color="white" fixed height="120px" elevate-on-scroll>
        <VAppBarNavIcon
          @click.stop="mobileMenu = !mobileMenu"
          v-if="$vuetify.breakpoint.smAndDown"
        />
        <v-row>
          <v-col
            cols="12"
            md="6"
            class="d-flex flex-column"
            :class="{ 'align-center': $vuetify.breakpoint.smAndDown }"
          >
            <v-img
              :src="require('../assets/logo.png')"
              @click="$router.push({ name: 'home' })"
              alt="FIS Automotive"
              :width="$vuetify.breakpoint.smAndDown ? 180 : 236"
              :class="{ 'mb-2': $vuetify.breakpoint.smAndDown }"
            />
            <div class="d-flex align-center">
              <v-btn
                icon
                color="black"
                :to="{ name: 'home' }"
                class="no-active mr-5"
                v-if="!$vuetify.breakpoint.smAndDown"
              >
                <v-icon>fas fa-home</v-icon>
              </v-btn>
              <v-btn
                color="primary"
                :to="{ name: 'order' }"
                :small="$vuetify.breakpoint.smAndDown"
                >Bestel direct</v-btn
              >
            </div>
          </v-col>

          <v-col
            cols="12"
            md="6"
            class="d-flex flex-column justify-center align-end"
            v-if="!$vuetify.breakpoint.smAndDown"
          >
            <div class="d-flex align-center">
              <v-btn
                text
                color="black"
                :to="{ name: 'login' }"
                v-if="!isLoggedIn"
                >Inloggen</v-btn
              >
              <v-btn text color="black" :to="{ name: 'profile' }" v-else>
                <v-icon x-small class="mr-2">$profile</v-icon>Profiel
              </v-btn>
              <v-badge
                v-model="hasOrderLines"
                color="red"
                dot
                offset-x="10"
                offset-y="10"
              >
                <v-btn icon color="black" :to="{ name: 'shopping-cart' }">
                  <v-icon>fas fa-shopping-cart</v-icon>
                </v-btn>
              </v-badge>
            </div>
            <div class="d-flex">
              <v-btn
                v-for="item in menuItems"
                :key="item.title"
                text
                color="black"
                :to="item.route"
                >{{ item.title }}</v-btn
              >
            </div>
          </v-col>
        </v-row>
        <v-badge
          v-if="$vuetify.breakpoint.smAndDown"
          v-model="hasOrderLines"
          color="red"
          dot
          offset-x="10"
          offset-y="10"
        >
          <v-btn icon color="black" :to="{ name: 'shopping-cart' }">
            <v-icon>fas fa-shopping-cart</v-icon>
          </v-btn>
        </v-badge>
      </v-app-bar>
      <v-main>
        <the-hero />
        <SnackbarQueue />
        <router-view />
      </v-main>
      <footer>
        <TheFooter />
      </footer>
    </v-app>
  </div>
</template>

<script>
import SnackbarQueue from "../components/SnackbarQueue.vue";
import TheFooter from "../components/TheFooter.vue";
import TheHero from "../components/Hero/TheHero.vue";
import { mapGetters, mapMutations } from "vuex";
import MainMenu from "../components/MainMenu.vue";
import { getAll } from "@/api/pages.js";

export default {
  name: "template-default",
  components: {
    SnackbarQueue,
    TheHero,
    TheFooter,
    MainMenu,
  },
  data() {
    return {
      mobileMenu: false,
      pages: [],
    };
  },
  created() {
    this.getPages();
    this.$hero.$on("setSnackBar", () => {
      this.large = true;
    });
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "authorisation/isLoggedIn",
      hasOrderLines: "order/hasOrderLines",
    }),
    menuItems() {
      return [
        {
          icon: "fa-question-circle",
          title: "FAQ",
          route: { name: "faq" },
        },
        ...this.pages.map((page) => ({
          icon: "fa-file",
          title: page.title,
          route: { name: "page-view.details", params: { slug: page.slug } },
        })),
        {
          icon: "fa-users",
          title: "Contact",
          route: { name: "contact" },
        },
      ];
    },
  },
  methods: {
    ...mapMutations({
      setAuthorisationToken: "authorisation/setAuthorisationToken",
    }),
    async getPages() {
      const { data } = await getAll();
      this.pages = data;
    },
  },
};
</script>

<style scoped lang="scss">
.logo {
  display: block;
  margin: 30px auto;
  width: 50%;
  cursor: pointer;
}
</style>
