<template>
  <v-col lg="5" md="6" class="white--text pa-12" v-if="$vuetify.breakpoint.mdAndUp">
    <h1 class="text-h3">FIS Automotive</h1>
    <p><b>Dé Specialist in Bedrijfswageninrichting</b></p>
  </v-col>
</template>

<script>
export default {
  name: 'HeroText',
};
</script>

<style scoped>

</style>
