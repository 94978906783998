<template>
  <v-col lg="2" md="4" sm="6" offset-sm="3" offset-md="1" offset-lg="4">
    <ArticleSearcher class="mt-5"/>
  </v-col>
</template>

<script>
import ArticleSearcher from '@/components/ArticleSearcher.vue';

export default {
  name: 'HeroArticleSearcher',
  components: { ArticleSearcher },
};
</script>

<style scoped>

</style>
