<template>
  <v-card outlined :class="{ active: selected }" @click="select">
    <v-icon color="primary" class="check-icon" v-if="selected" size="40"
      >fas fa-check-circle</v-icon
    >
    <v-card-text>
      <v-img contain :src="media" aspect-ratio="1" width="100%" />
      <span v-if="displayName">{{ item ? item.name : "" }}</span>
      <ImplementationDialog
        v-if="item.description"
        v-model="item.description"
      />
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn color="primary" @click="select">{{ selectText }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ImplementationDialog from "./Order/ImplementationDialog.vue";
import { getPlaceholder } from "@/application/util/imagePlaceholder.js";

export default {
  name: "SelectableOption",
  components: { ImplementationDialog },
  props: {
    value: {
      type: Object,
      default: null,
    },
    selectText: {
      type: String,
      required: false,
      default: "Selecteer",
    },
    item: {
      type: Object,
      required: false,
    },
    displayName: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  computed: {
    media() {
      return this.item.image?.url ?? getPlaceholder();
    },
    selected() {
      return this.item && this.value && this.value.id === this.item.id;
    },
  },
  methods: {
    select() {
      this.$emit("selected");
    },
  },
};
</script>

<style scoped>
.check-icon {
  position: absolute;
  top: -18px;
  right: -18px;
  background: white;
}
</style>
