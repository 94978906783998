<template>
  <v-snackbar v-model="snackbar" :color="color">
    {{ text }}
  </v-snackbar>
</template>

<script>
export default {
  name: 'SnackbarQueue',
  data() {
    return {
      queue: [],
      snackbar: false,
      text: '',
      color: '',
      queuerIsBusy: false,
    };
  },
  watch: {
    queue: {
      deep: true,
      handler() {
        if (this.queue.length > 0 && !this.queuerIsBusy) {
          this.displayNextSnackbar();
        }
      },
    },
  },
  created() {
    this.$hero.$on('setSnackbar', (snackbar) => {
      if (typeof snackbar === 'string') {
        snackbar = { text: snackbar };
      }
      this.queue.push({
        text: snackbar.text,
        color: snackbar.color || 'primary',
      });
    });
  },
  methods: {
    displayNextSnackbar() {
      this.queuerIsBusy = true;
      const snackbar = this.queue[0];
      this.text = snackbar.text;
      this.color = snackbar.color;
      this.snackbar = true;
      setTimeout(() => {
        this.snackbar = false;
        this.queue.shift();
        if (this.queue.length > 0) {
          setTimeout(() => {
            this.displayNextSnackbar();
          }, 500);
        } else {
          this.queuerIsBusy = false;
        }
      }, 4000);
    },
  },
};
</script>
