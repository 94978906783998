<template>
  <div>
    <v-row class="primary white--text pt-8 ma-0"
           :class="{'text-center' : $vuetify.breakpoint.xsOnly}"
           :justify="footerJustifyment">
      <v-col cols="12" sm="6" md="2">
        <h4 class="text-h6">Klantenservice</h4>
        <span class="text-subtitle-1">
          <router-link :to="{name: 'contact'}" class="white--text">
            Contact
          </router-link>
        </span>
        <br/>
        <span class="text-subtitle-1">
          <a href="/files/Algemene%20voorwaarden.pdf" target="_blank" class="white--text">
            Algemene voorwaarden
          </a>
        </span>
        <br/>
        <span class="text-subtitle-1">
          <a href="/files/Herroepingsrecht.pdf" target="_blank" class="white--text">Herroepingsrecht</a> en
          <a href="/files/Privacybeleid.pdf" target="_blank" class="white--text">privacy beleid</a>
        </span>
      </v-col>
      <v-col cols="12" sm="6" md="2">
        <h4 class="text-h6">Adres</h4>
        <span class="text-subtitle-1">Slibbroek 44</span>
        <br/>
        <span class="text-subtitle-1">5081 NS Hilvarenbeek</span>
        <br/>
        <span class="text-subtitle-1">013-505 32 72</span>
      </v-col>
      <v-col cols="12" sm="6" md="auto">
        <h4 class="text-h6">Volg ons</h4>
        <span class="text-subtitle-1">
            <a href="https://www.facebook.com/FISAutomotiveBV/" target="_blank" rel="noopener noreferrer">
              <v-icon color="white" class="mr-3">fab fa-facebook-square</v-icon>
              Facebook
            </a>
          </span>
        <br/>
        <span class="text-subtitle-1">

            <a href="https://www.linkedin.com/company/103804110/"
               target="_blank"
               rel="noopener noreferrer">
              <v-icon color="white" class="mr-3">fab fa-linkedin</v-icon>
              Linkedin
            </a>
          </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'TheFooter',
  computed: {
    footerJustifyment() {
      return this.$vuetify.breakpoint.mdAndUp ? 'center' : 'start';
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
